// Generated by Framer (24f4d74)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState, RichText } from "framer";

const cycleOrder = ["hwaYZeWiS"];

const variantClassNames = {hwaYZeWiS: "framer-v-h0va2p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: string; tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "hwaYZeWiS", image: TOWhNFoU9 = new URL("assets/512/btABeI6Hl5S39HVsbrYcaj9vIps.jpg", import.meta.url).href, tap: JvuDgVP5B, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "hwaYZeWiS", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant);

const onTaph0va2p = activeVariantCallback(async (...args) => {
if (JvuDgVP5B) {
const res = await JvuDgVP5B(...args);
if (res === false) return false;
}
})

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Si7sG", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-h0va2p", className)} style={{...style}} layoutId={"hwaYZeWiS"} transition={transition} layoutDependency={layoutDependency} background={null} data-highlight={true} data-framer-name={"Variant 1"} onTap={onTaph0va2p} ref={ref}><Image className={"framer-ed9qg1"} style={{borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopRightRadius: 40, borderTopLeftRadius: 40}} layoutId={"RkODDH7EL"} transition={transition} layoutDependency={layoutDependency} background={{src: TOWhNFoU9, pixelWidth: 1252, pixelHeight: 1218, intrinsicWidth: 1252, intrinsicHeight: 1218, fit: "fill"}} data-framer-name={"Logo Image"} alt={""}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Si7sG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Si7sG * { box-sizing: border-box; }", ".framer-Si7sG .framer-h0va2p { position: relative; cursor: pointer; overflow: visible; width: min-content; height: min-content; display: flex; flex-direction: row; justify-content: flex-start; align-content: center; align-items: center; flex-wrap: nowrap; gap: 10px; padding: 0px 0px 0px 0px; }", ".framer-Si7sG .framer-ed9qg1 { position: relative; overflow: visible; width: 39px; height: 39px; flex: none; }", ".framer-Si7sG .framer-kk2bzn { position: relative; overflow: visible; width: auto; height: auto; flex: none; white-space: pre; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Si7sG framer-h0va2p > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Si7sG framer-h0va2p > :first-child { margin-left: 0px; } .framer-Si7sG framer-h0va2p > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 39
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"TOWhNFoU9":"image","JvuDgVP5B":"tap"}
 */
const FramerTSD5okHOa: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerTSD5okHOa;

FramerTSD5okHOa.displayName = "Template/Simple resume 1 > Molecules > Navigation > Logo";

FramerTSD5okHOa.defaultProps = {width: 39, height: 39};

addPropertyControls(FramerTSD5okHOa, {TOWhNFoU9: {type: ControlType.Image, title: "Image", __defaultAssetReference: "data:framer/asset-reference,btABeI6Hl5S39HVsbrYcaj9vIps.jpg?originalFilename=Sankalp_Sinha_5-2.jpg&preferredSize=auto"}, JvuDgVP5B: {type: ControlType.EventHandler, title: "Tap"}} as any);

addFonts(FramerTSD5okHOa, []);